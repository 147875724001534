
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$gray-light: #ddd;
$plastic-card-gray: #7d95a9;
$black: #333;

.plastic-card__back {
  display: inline-block;
  width: auto;
  img {
    height: 300px;
    border: 1px solid $gray-light;

    @include for-size(phone-portrait-down) {
      height: 209px;
    }
  }
}
.card__img {
  position: relative;
}

.plastic-card__site-code {
  position: absolute;
  top: 52px;
  left: 40px;
  border: 3px solid $plastic-card-gray;

  img {
    width: 100%;
    height: 100%;
  }

  @include for-size(phone-portrait-down) {
    width: 130px;
    height: 130px;
    top: 30px;
  }
}
.plastic-card__contact-code {
  position: absolute;
  top: 44px;
  right: 20px;
  border: 3px solid $plastic-card-gray;

  img {
    width: 100%;
    height: 100%;
  }

  @include for-size(phone-portrait-down) {
    width: 120px;
    height: 120px;
  }
}

.card__item {
  width: 466px;
}
.card__inner {
  height: 300px;
  min-height: 299px;
  padding: 25px 20px 20px 20px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ddd;

  @include for-size(phone-portrait-down) {
    min-height: 209px;
    height: 209px;
    padding: 7px 10px 10px 15px;
  }

}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 180px;
}
.card__logo {
  padding: 0 0 35px;
  line-height: 0;
  img {
    width: 100%;
    max-width: 160px;
    height: auto;
  }
  @include for-size(phone-portrait-down) {
    padding-bottom: 7px;
  }
}
.card__employee {
  padding: 0 0 12px;
  font-family: 'Rostelecom Basis', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: $black;
  @include for-size(phone-portrait-down) {
    font-size: 13px;
    line-height: 16px;
  }
}
.card-name,
.card-patronymic {
  display: inline-block;
  width: auto;
}
.card__position,
.card__branch,
.card__department {
  font-family: 'Rostelecom Basis', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $black;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
  }
}
.plastic-card__front {
  position: relative;
}

.visit-card-layout-cnt {
  width: 100%;
  overflow: hidden;
}
