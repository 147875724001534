
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.card__inner {
  height: 300px;
  min-height: 299px;
  padding: 15px 20px 20px 30px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ddd;

  @include for-size(phone-portrait-down) {
    height: 209px;
    min-height: 209px;
    padding: 7px 10px 10px 15px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.card__logo {
  padding: 0 0 15px;
  line-height: 0;
  img {
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-left: -10px;
  }
  @include for-size(phone-portrait-down) {
    padding-bottom: 7px;
  }
}
.card__employee {
  padding: 0 0 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  color: #000;

  @include for-size(phone-portrait-down) {
    font-size: 13px;
    line-height: 16px;
  }
}
.card-name,
.card-patronymic {
  display: inline-block;
  width: auto;
}
.card__position {
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  color: #000;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
  }
}
.card__branch {
  margin-top: 6px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #000;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
  }
}
.card__department {
  margin-top: 3px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #000;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
  }
}
.card__body {
  float: right;
  width: 100%;
  padding-top: 162px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  text-align: left;
  @include for-size(phone-portrait-down) {
    font-size: 12px;
    line-height: 12px;
    padding-top: 120px;

    p {
      line-height: 14px;
    }
  }
}
.card__img {
  overflow: hidden;
  img {
    height: 300px;
    display: block;
    @include for-size(phone-portrait-down) {
      height: 209px;
    }
  }
}

.visit-card-layout-cnt {
  width: 100%;
  overflow: hidden;
}
