
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.created--card .created-app {
  margin: 0 0 32px;
}

.created--card .created__form {
  padding: 0;
}

.created--card .created__footer {
  padding: 45px 0 0;
  border: 0;
}

.card {
  max-width: 940px;
  margin: 0 0 30px;
}

.card__item {
  width: 460px;
  margin: 0 auto;

  @include for-size(phone-portrait-down) {
    width: 100%;
    max-width: 320px;
  }
}

.card__label {
  margin-bottom: 10px;
}

.card__left {
  padding-right: 10px;
}

.card__right {
  padding-left: 10px;
}

.card__row {
  display: flex;
  flex-wrap: wrap;

  @include for-size(tablet-landscape-down) {
    flex-direction: column;
  }
}
.card__col {
  width: 50%;

  &:not(:last-child) {
    @include for-size(tablet-landscape-down) {
      margin-bottom: 20px;
    }
  }

  @include for-size(phone-portrait-down) {
    width: 100%;
  }
}
